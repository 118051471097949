$(document).ready(function () {
  $(".dsa-secure-plugin").each(function () {
    dsaurl = $(this).data("dsa-secure-plugin-url");
    dsapolice = $(this).data("dsa-secure-police");
    dsaimage = $(this).data("dsa-secure-image");
    dsaname = "Plugin";
    if ($(this).data("dsa-secure-name")) {
      dsaname = $(this).data("dsa-secure-name");
    }
    dsawidth = "480";
    if ($(this).data("dsa-secure-width")) {
      dsawidth = $(this).data("dsa-secure-width");
    }
    dsaheight = "280";
    if ($(this).data("dsa-secure-height")) {
      dsaheight = $(this).data("dsa-secure-height");
    }
    appendContent = '<div class="dsa-secure-image"></div><div class="check-slide"><div class="check-slide-content"><label class="dsa-switch"><input type="checkbox" class="dsa-secure-plugin-checkbox"><span class="dsa-slider round"></span></label><p class="undercheck">Aktivieren um ' + dsaname + ' anzuzeigen</p><div class="dsa-buttons"><a class="dsa-datennutzung dsa-tooltip " href="#" >Hinweis zur Datennutzung<span class="tooltiptext">Mit Setzen des Häkchens im obenstehenden Kontrollkästchen erklären Sie sich einverstanden, dass die von Ihnen angegebenen Daten elektronisch erhoben und gespeichert werden. Ihre Daten werden dabei nur streng zweckgebunden zur Bearbeitung und Beantwortung Ihrer Anfrage genutzt. Diese Einwilligung können Sie jederzeit durch Nachricht an uns widerrufen. Im Falle des Widerrufs werden Ihre Daten umgehend gelöscht.</span></a><a href="' + dsapolice + '" target="_blank">Datenschutzerklärung</a></div>  </div>  </div>  </div>';
    $(this).width(dsawidth).height(dsaheight).append(appendContent);
  });
  $(".dsa-secure-plugin-checkbox").on("change", function () {
    dsatarget = $(this).data("dsa-secure-plugin-target");
    dsaparent = $(this).parent().parent().parent().parent();
    dsahidetarget = $(this).parent().parent().parent();
    dsareplaceTarget = dsaparent.find(".dsa-secure-image");
    dsaiurl = dsaparent.data("dsa-secure-plugin-url");
    dsaiwidth = "480";
    if (dsaparent.data("dsa-secure-width")) {
      dsaiwidth = dsaparent.data("dsa-secure-width");
    }
    dsaiheight = "280";
    if (dsaparent.data("dsa-secure-height")) {
      dsaiheight = dsaparent.data("dsa-secure-height");
    }
    if (this.checked) {
      setTimeout(function () {
        $.post("", {target: dsaiurl});
        dsahidetarget.addClass("hide");
        dsareplaceTarget.replaceWith($('<iframe  src="' + dsaiurl + '" frameborder="0" width="' + dsaiwidth + '" height="' + dsaiheight + '"  encrypted-media" allowfullscreen></iframe>'));
      }, 400);
    }
  });
  $(".dsa-secure-plugin  .tooltip").on("click", function (e) {
    e.preventDefault();
    $(this).toggleClass("active");
  });
});
